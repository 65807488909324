<template>
    <div>
        支付已取消
    </div>
</template>

<script>
export default {
    data(){
        return{
            
        }
    }
}
</script>

<style lang="less" scoped>
    
</style>